import React from 'react'
import { Layout, ContactBanner, Footer, Section, SEO } from '../../components'
import { useTranslation } from 'react-i18next'

const RiceTrading = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t('titlePages:riceTrading')} />
      <Section title='riceTrading:title' text='riceTrading:main-text' />
      <ContactBanner />
      <Footer />
    </Layout>
  )
}

export default RiceTrading
